import React from "react";

const Mission = () => (
  <React.Fragment>
    <div>
      <section className="container">
        <div className="hero-container pageBannerPadding">
          <div className="row">
            <div className="col-md-6">
              <h1>On mission</h1>
              <h2>
                To build technology, tools and standards that enable authentic
                journalism to grow, reach and impact everyday lives.
              </h2>
            </div>
            <div className="col-md-5">
              <img
                src="../assets/img/mission.png"
                alt="badge"
                className="badgeSize"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section class="hero-image solutions compliance">
        <div class="hero-container">
          <div class="container">
            <div class="hero-title">
              <h1 class="title-section">Digital security with XCheck </h1>
            </div>
            <div class="summary">
              <p class="hero-text">
                Rampant sharing of fake news and misinformation has raised
                credibility issues for media organisations and independent
                publishers who have little or no idea about the ways in which
                their content can be misused online by notorious sources. This
                makes digital protection of online content extremely necessary
                for publishers, in recent times.
              </p>
            </div>
            <img
              class="img-web"
              id="img_web"
              src="../assets/img/compliance/hero_compliance.svg"
              alt="desc"
            />
          </div>
        </div>
      </section> */}

      <section className="section-grey">
        <div className="container g-pt-100 g-pb-100">
          <div className="g-mb-50 col-lg-12 without">
            <h2 className="h1 title-size title-grey text-center">
              {" "}
              Our Values
            </h2>
            <p className="text-center g-font-size-18 mx-auto ">
              Work we do is mere reflection of our philosophy, believes and
              values. <br />
              At XCheck, values inform our behaviour and choices we make
              everyday. <br />
              As a result, our culture is a model of the world we’re trying to
              build.
            </p>
          </div>
          <div className="g-mt-50 selling-points industry mb-4">
            <div className="item-element text-center">
              <img src="../assets/img/1ic_autoria.png" alt="svg_icon" />
              <div className="img-text">
                <div className="title">Bold</div>
                <p className="text-center g-font-size-18 mx-auto ">
                  We are confident, courageous, and directed.
                </p>
              </div>
            </div>
            <div className="item-element text-center">
              <img
                src="../assets/img/4ic_transparencia.svg"
                alt=" Description"
              />
              <div className="img-text">
                <div className="title">Responsible</div>
                <p className="text-center g-font-size-18 mx-auto ">
                  Our intuitive and empathic thinking have made us.
                </p>
              </div>
            </div>
            <div className="item-element text-center">
              <img src="../assets/img/2ic_fecha.svg" alt=" Description" />
              <div className="img-text">
                <div className="title">Creative</div>
              </div>
              <p className="text-center g-font-size-18 mx-auto ">
                World is sandbox of life and experiments. We play!
              </p>
            </div>
            <div className="item-element text-center">
              <img src="../assets/img/3ic_integridad.svg" alt=" Description" />
              <div className="img-text">
                <div className="title">Lead</div>
                <p className="text-center g-font-size-18 mx-auto ">
                  By default as we walk down road less travelled.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-industries g-pt-70 g-pb-50--lg">
        <div className="container">
          <div className="row d-flex align-items-lg-center flex-wrap">
            <div className="col-md-12 col-lg-6 g-mb-50--md g-pr-60--lg">
              <img
                className="img-fluid wd-100"
                src="../assets/img/mission-1.png"
                alt=" Description"
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="text-mobile-finance g-ml-20 g-ml-0--sm">
                <div className="g-mb-20">
                  <div className="g-mb-40">
                    <h2 className="h1 title-color">Working at XCheck</h2>
                  </div>
                  <p className="g-font-size-16 summary-font">
                    We’ve taken a huge challenge and made it into our mission:
                    To protect digital journalism from abuse. To achieve this,
                    we are building a team of smart, creative, passionate
                    optimists, the kind of people who see opportunity where
                    others see roadblocks. If this sounds like you, we are
                    waiting to talk with you.
                  </p>
                  <br />
                  <a
                    className="u-link-v5 g-cursor-pointer product-btn-green"
                    href="\"
                  >
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Join Us
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </React.Fragment>
);

export default Mission;
