import React from "react";

const Products = () => (
  <React.Fragment>
    <section className="section-products">
      <div className="container g-py-100--md g-py-50--sm">
        <div className="g-mb-50 without">
          <img
            className="square-img"
            src="assets/img/title-square.svg"
            alt="icon"
          />
          <h2 className="h1 title-size title-grey">Stakeholders</h2>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-6 g-pr-25--lg">
            <div className="u-shadow-v1-5 box-product">
              <div className="text-box">
                <h2>Publishers</h2>
                <p className="summary-font">
                  Protect your work from unsolicited manipulation, replication
                  and distribution over internet. Get recognized for your work.
                </p>
                <a
                  className="u-link-v5 g-cursor-pointer product-btn-green"
                  href="\publishers"
                >
                  Start For Free
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6 g-pl-25--lg">
            <div className="u-shadow-v1-5 box-product">
              <div className="text-box core">
                <h2>New Agency</h2>
                <p className="summary-font">
                  Protect news distribution pipeline, empower partners and grow
                  revenue with effective price discovery of news. Get
                  Iris360&trade;
                </p>
                <a
                  className="u-link-v5 g-cursor-pointer product-btn-green"
                  href="\newsagency"
                >
                  Know More
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6  pt-3"></div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default Products;
