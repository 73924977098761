import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import HomeLayouts from "./layouts/HomeLayouts";
import About from "./pages/About";
import Academia from "./pages/Academia";
import Advisory from "./pages/Advisory";
import Career from "./pages/Career";
import Donations from "./pages/Donations";
import FakeNews from "./pages/FakeNews";
import FAQ from "./pages/FAQ";
import Feedback from "./pages/Feedback";
import GDPR from "./pages/GDPR";
import General from "./pages/General";
import Government from "./pages/Government";
import Hallofshame from "./pages/Hallofshame";
import Hotline from "./pages/Hotline";
import Howitworks from "./pages/Howitworks";
import Journalist from "./pages/Journalist ";
import Media from "./pages/Media";
import NewsAgency from "./pages/NewsAgency";
import Ourinvestors from "./pages/Ourinvestors";
import Partners from "./pages/Partners";
import Privacypolicy from "./pages/Privacypolicy";
import Publisher from "./pages/Publisher";
import Reader from "./pages/Reader";
import Resources from "./pages/Resources";
import Team from "./pages/Team";
import Technology from "./pages/Technology";
import Termsofservice from "./pages/Termsofservice";
import Whytrust from "./pages/Whytrust";
import Xchecklabels from "./pages/Xchecklabels";
import Blockchain from "./pages/Blockchain";
import Support from "./pages/Support";
import Mission from "./pages/Mission";
import Register from "./pages/Register";

const register = () => (
  <HomeLayouts>
    <Register />
  </HomeLayouts>
);

const about = () => (
  <HomeLayouts>
    <About />
  </HomeLayouts>
);

const academia = () => (
  <HomeLayouts>
    <Academia />
  </HomeLayouts>
);

const advisory = () => (
  <HomeLayouts>
    <Advisory />
  </HomeLayouts>
);

const career = () => (
  <HomeLayouts>
    <Career />
  </HomeLayouts>
);

const donations = () => (
  <HomeLayouts>
    <Donations />
  </HomeLayouts>
);

const fakenews = () => (
  <HomeLayouts>
    <FakeNews />
  </HomeLayouts>
);

const faq = () => (
  <HomeLayouts>
    <FAQ />
  </HomeLayouts>
);

const feedback = () => (
  <HomeLayouts>
    <Feedback />
  </HomeLayouts>
);

const gdpr = () => (
  <HomeLayouts>
    <GDPR />
  </HomeLayouts>
);

const general = () => (
  <HomeLayouts>
    <General />
  </HomeLayouts>
);

const government = () => (
  <HomeLayouts>
    <Government />
  </HomeLayouts>
);
const hallofshame = () => (
  <HomeLayouts>
    <Hallofshame />
  </HomeLayouts>
);
const hotline = () => (
  <HomeLayouts>
    <Hotline />
  </HomeLayouts>
);
const howitworks = () => (
  <HomeLayouts>
    <Howitworks />
  </HomeLayouts>
);
const journalist = () => (
  <HomeLayouts>
    <Journalist />
  </HomeLayouts>
);
const media = () => (
  <HomeLayouts>
    <Media />
  </HomeLayouts>
);
const newsagency = () => (
  <HomeLayouts>
    <NewsAgency />
  </HomeLayouts>
);
const ourinvestors = () => (
  <HomeLayouts>
    <Ourinvestors />
  </HomeLayouts>
);
const partners = () => (
  <HomeLayouts>
    <Partners />
  </HomeLayouts>
);
const privacypolicy = () => (
  <HomeLayouts>
    <Privacypolicy />
  </HomeLayouts>
);

const publisher = () => (
  <HomeLayouts>
    <Publisher />
  </HomeLayouts>
);

const reader = () => (
  <HomeLayouts>
    <Reader />
  </HomeLayouts>
);

const resources = () => (
  <HomeLayouts>
    <Resources />
  </HomeLayouts>
);

const team = () => (
  <HomeLayouts>
    <Team />
  </HomeLayouts>
);

const technology = () => (
  <HomeLayouts>
    <Technology />
  </HomeLayouts>
);
const termsofservice = () => (
  <HomeLayouts>
    <Termsofservice />
  </HomeLayouts>
);

const whytrust = () => (
  <HomeLayouts>
    <Whytrust />
  </HomeLayouts>
);

const xchecklabel = () => (
  <HomeLayouts>
    <Xchecklabels />
  </HomeLayouts>
);

const blockchain = () => (
  <HomeLayouts>
    <Blockchain />
  </HomeLayouts>
);

const support = () => (
  <HomeLayouts>
    <Support />
  </HomeLayouts>
);

const mission = () => (
  <HomeLayouts>
    <Mission />
  </HomeLayouts>
);
function App() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={about} />
      <Route exact path="/academia" component={academia} />
      <Route exact path="/advisory" component={advisory} />
      <Route exact path="/career" component={career} />
      <Route exact path="/donations" component={donations} />
      <Route exact path="/fakenews" component={fakenews} />
      <Route exact path="/faq" component={faq} />
      <Route exact path="/feedback" component={feedback} />
      <Route exact path="/gdpr" component={gdpr} />
      <Route exact path="/general" component={general} />
      <Route exact path="/hallofshame" component={hallofshame} />
      <Route exact path="/hotline" component={hotline} />
      <Route exact path="/howitworks" component={howitworks} />
      <Route exact path="/journalist" component={journalist} />
      <Route exact path="/media" component={media} />
      <Route exact path="/newsagency" component={newsagency} />
      <Route exact path="/ourinvestors" component={ourinvestors} />
      <Route exact path="/partners" component={partners} />
      <Route exact path="/privacypolicy" component={privacypolicy} />
      <Route exact path="/reader" component={reader} />
      <Route exact path="/resources" component={resources} />
      <Route exact path="/team" component={team} />
      <Route exact path="/termsofservice" component={termsofservice} />
      <Route exact path="/whytrust" component={whytrust} />
      <Route exact path="/xchecklabel" component={xchecklabel} />
      <Route exact path="/technology" component={technology} />
      <Route exact path="/publisher" component={publisher} />
      <Route exact path="/government" component={government} />
      <Route exact path="/blockchain" component={blockchain} />
      <Route exact path="/support" component={support} />
      <Route exact path="/mission" component={mission} />
      <Route exact path="/register" component={register} />
    </Router>
  );
}

export default App;
