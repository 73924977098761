import React from "react";
import axios from "axios";
// import RetroHitCounter from "react-retro-hit-counter";

class Others extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: "-",
    };
  }

  componentDidMount(){
    axios.get("https://xcheck-smart.herokuapp.com/count").then((res)=> {
      console.log(res)
      this.setState({counter: res.data.count})
    })
  }
  countUpdates() {
    this.setState({
      counter: this.state.counter + 1,
    });
  }

  render() {
    return (
      <div style={style}>
        <h1 style={{ fontSize: "40px" }}>{this.state.counter} Articles</h1>
        <h3>Protected on Internet</h3>
      </div>
    );
  }
}

export default Others;

const style = {
  background: "#000",
  padding: "60px",
  color: "#4DF715",
  fontSize: 80,
  textAlign: "center",
};
