/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Navbar = () => (
  <React.Fragment>
    <header id="js-header" className="u-header u-header--sticky-top">
      <div
        id="js-header-section"
        className="u-header__section g-bg-white g-transition-0_3 g-py-10"
      >
        <nav className="js-mega-menu navbar navbar-toggleable">
          <div className="container">
            <button
              className="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-right-0"
              type="button"
              aria-label="Toggle navigation"
              aria-expanded="false"
              aria-controls="navBar"
              data-target="#navBar"
            >
              <span className="hamburger hamburger--slider">
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </span>
            </button>
            <a href="/" className="navbar-brand">
              <img id="logo-img" src="/xcheck.png" alt="xchcek" width={180} />
            </a>
            <div
              className="js-navigation navbar-collapse align-items-center flex-sm-row u-main-nav--overlay-hidden g-bg-white navigator-top"
              id="navBar"
              data-navigation-breakpoint="lg"
              data-navigation-position="left"
              data-navigation-init-classes="g-transition-0_5"
              data-navigation-init-body-classes="g-transition-0_5"
              data-navigation-overlay-classes="g-bg-black-opacity-0_8 g-transition-0_5"
            >
              <div className="u-main-nav__list-wrapper ml-auto">
                <ul className="navbar-nav ml-auto u-main-nav-v5 u-sub-menu-v1 header-mobile">
                  <li className="nav-item hs-has-sub-menu g-mx-15--xl">
                    How it Works? <i className="fa fa-angle-down" />
                    <ul
                      className="hs-sub-menu list-unstyled g-brd-top g-brd-top-2 g-py-7 g-mt-10 g-mt-10--lg--scrolling"
                      id="nav-submenu--home"
                      aria-labelledby="nav-link--home"
                      style={{ display: "none" }}
                    >
                      <li className="dropdown-item">
                        <a
                          className="blue-product text-uppercase"
                          href="/whytrust"
                        >
                          <img
                            className="square-sub-menu cheka"
                            src="assets\img\title-square.svg"
                          />
                          Why Trust ?
                        </a>
                      </li>
                      <li className="dropdown-item">
                        <a
                          className="blue-product text-uppercase"
                          href="/xchecklabel"
                        >
                          <img
                            className="square-sub-menu"
                            src="assets\img\title-square.svg"
                          />
                          XCheck Label
                        </a>
                      </li>

                      {/* <li className="dropdown-item">
                        <a
                          className="blue-product text-uppercase"
                          href="/technology"
                        >
                          <img
                            className="square-sub-menu"
                            src="assets\img\title-square.svg"
                          />
                          Technology
                        </a>
                      </li> */}

                      <li className="dropdown-item">
                        <a className="blue-product text-uppercase" href="/faq">
                          <img
                            className="square-sub-menu"
                            src="assets\img\title-square.svg"
                          />
                          FAQ
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item hs-has-sub-menu g-mx-15--xl">
                    About <i className="fa fa-angle-down" />
                    <ul
                      className="hs-sub-menu list-unstyled g-brd-top g-brd-top-2 g-py-7 g-mt-10 g-mt-10--lg--scrolling"
                      id="nav-submenu--home"
                      aria-labelledby="nav-link--home"
                      style={{ display: "none" }}
                    >
                      <li className="dropdown-item">
                        <a
                          className="blue-product text-uppercase"
                          href="/mission"
                        >
                          <img
                            className="square-sub-menu cheka"
                            src="assets\img\title-square.svg"
                          />
                          Mission
                        </a>
                      </li>

                      <li className="dropdown-item">
                        <a className="blue-product text-uppercase" href="/team">
                          <img
                            className="square-sub-menu cheka"
                            src="assets\img\title-square.svg"
                          />
                          People
                        </a>
                      </li>

                      {/* <li className="dropdown-item">
                        <a
                          className="blue-product text-uppercase"
                          href="/ourinvestors"
                        >
                          <img
                            className="square-sub-menu"
                            src="assets\img\title-square.svg"
                          />
                          Investors
                        </a>
                      </li> */}
                    </ul>
                  </li>

                  <li className="nav-item hs-has-sub-menu g-mx-15--xl">
                    Partners <i className="fa fa-angle-down" />
                    <ul
                      className="hs-sub-menu list-unstyled g-brd-top g-brd-top-2 g-py-7 g-mt-10 g-mt-10--lg--scrolling"
                      id="nav-submenu--home"
                      aria-labelledby="nav-link--home"
                      style={{ display: "none" }}
                    >
                      <li className="dropdown-item">
                        <a
                          className="blue-product text-uppercase"
                          href="/publisher"
                        >
                          <img
                            className="square-sub-menu"
                            src="assets\img\title-square.svg"
                          />
                          Publisher
                        </a>
                      </li>

                      <li className="dropdown-item">
                        <a
                          className="blue-product text-uppercase"
                          href="/newsagency"
                        >
                          <img className="square-sub-menu" src="/newsagency" />
                          News Agency
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/* <li className="nav-item">
                    <a
                      href="https://medium.com/@xcheckit"
                      className="section-link g-px-0"
                      target="_blank"
                    >
                      Blog
                    </a>
                  </li> */}
                  <li className="nav-item g-valign-middle">
                    <a
                      href="/"
                      className="register g-font-size-14 product-btn-green "
                      href="https://gawravmehta.typeform.com/to/X9szGwPy"
                      target="_blank"
                    >
                      Schedule Demo
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  </React.Fragment>
);

export default Navbar;
