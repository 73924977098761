import React from "react";

const Whytrust = () => (
  <React.Fragment>
    <div>
      <section className="container">
        <div className="hero-container pageBannerPadding">
          <div className="row">
            <div className="col-md-7">
              <h2>Digital Journalism over Internet</h2>
              <div className="summary mt-5">
                <p className="g-font-size-20 summary-font">
                  <i>Protection, Recognition</i> and <i>Regulating</i> the
                  online usage of journalist's work is fundamental to
                  sustainable journalism and revenues. News Publishers and
                  agency struggle with protection of news from plagiarism,
                  manipulation and unsolicited distribution. supply chain, till
                  news are consumed by end readers.
                </p>

                <p className="g-font-size-20 summary-font">
                  XCheck&trade; provides innovative and fundamental solution
                  that works to advance cause of community in digital world.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/img/products/why-trust.png"
                alt="badge"
                className="badgeSize"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-grey">
        <div className="container g-pt-100 g-pb-100">
          <h2 className="h1 title-size title-grey text-center">
            Why Trust is important in news?
          </h2>
          <p className="text-center g-font-size-20 mx-auto ">
            To measure Turst, YouGov conducted the online surveys of 40
            countries for the Reuters Institute with 2,000 respondents
            participating in each country. The survey found trust in news had
            fallen to its lowest level since the first report in 2012, with just
            38 percent saying they trusted most news most of the time.
          </p>
        </div>
      </section>

      <section className="section-white-industries">
        <div className="container g-pt-100 g-pb-70">
          <div className="g-mb-50">
            <h2 className="h1 title-color">Why trust XCheck? </h2>
          </div>
          <div className="g-mt-minus-30 g-mt-0--md">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="item-check">
                  <div className="text-check">
                    <div className="hr">
                      <hr />
                    </div>
                    <p className="g-font-size-16 text-epigraphy">
                      As internet expands to 7 billion people, there is growing
                      skepticism over digital journalism due to penetration of
                      fake news and abuse of digital medium for spreading
                      misinformation.
                    </p>
                   <br/>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Open Source initiative
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Industry Collabrative
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Public News Register&trade;
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Easy Cross Verification
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <img
                  className="img-fluid wd-100"
                  src="../assets/img/products/why-trust-1.png"
                  alt=" Description"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </React.Fragment>
);

export default Whytrust;
