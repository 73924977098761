import React from "react";

const Publisher = () => (
  <React.Fragment>
    <div>
      <section className="container">
        <div className="hero-container pageBannerPadding">
          <div className="row">
            <div className="col-md-12">
              <div className="w-60 mx-auto">
                <h1 className="text-center newsText">
                  News Publishers are information leaders
                </h1>
                <div className="summary mt-5">
                  <p className="g-font-size-20 summary-font text-center lineHeight">
                    Enabling news media leaders to protect information, brand,
                    fight misinformation and, and assist in unlocking news
                    opportunities on scale of Internet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-industries g-pt-70 g-pb-50--lg">
        <div className="container">
          <div className="row d-flex align-items-lg-center flex-wrap">
            <div className="col-md-12 col-lg-6 g-mb-50--md g-pr-60--lg">
              <img
                className="img-fluid wd-100 center"
                src="../assets/img/publisher.png"
                alt=" Description"
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="text-mobile-finance g-ml-20 g-ml-0--sm">
                <div className="g-mb-20">
                  <div className="g-mb-40">
                    <h2 className="h1 title-color">
                      Challenges for Online Publishers.
                    </h2>
                  </div>
                  <p className="g-font-size-16 summary-font">
                    News publishers invest heavily on infrastructure, journalist
                    resources and creating authentic journalism. Apart from
                    that, thousands of dollars are paid to third party News
                    Agency for exclusive news content.On internet work remains
                    unprotected providing opportunity for news portals to
                    copy-paste and abuse, hurting reader traffic, brand value,
                    revenues and business.
                  </p>
                  <br />
                  <a
                    className="u-link-v5 g-cursor-pointer product-btn-green"
                    href="\newsagency"
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-white-industries">
        <div className="container g-pt-100 g-pb-70">
          <div className="g-mb-50">
            <h2 className="h1 title-color text-center">
              How News Publisher use XCheck?{" "}
            </h2>
          </div>
          <div className="g-mt-minus-30 g-mt-0--md">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="item-check">
                  <div className="text-check">
                    <p className="g-font-size-16 text-epigraphy">
                      With XCheck &trade; News Publishers protect authentic
                      journalism, brand value and therefore revenues as
                      explained below:
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Content Security
                    </p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Content security details It is a long established fact
                      that a reader will be distracted by the readable content
                      of a page when looking at its layout with additional
                      information will come here.
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Brand Protection
                    </p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Brand Protection details It is a long established fact
                      that a reader will be distracted by the readable content
                      of a page when looking at its layout with additional
                      information will come here.
                    </p>
                  </div>
                </div>

                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Due Acknowledgement
                    </p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Due Acknowledgement details It is a long established fact
                      that a reader will be distracted by the readable content
                      of a page when looking at its layout with additional
                      information will come here.
                    </p>
                  </div>
                </div>

                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Syndiction &amp; Revenue
                    </p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Syndication details It is a long established fact that a
                      reader will be distracted by the readable content of a
                      page when looking at its layout with additional
                      information will come here.
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Deep Intelligence
                    </p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Deep Intelligence details It is a long established fact
                      that a reader will be distracted by the readable content
                      of a page when looking at its layout with additional
                      information will come here.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </React.Fragment>
);

export default Publisher;
