/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Xchecklabels = () => (
  <React.Fragment>
    <div>
      <section className="container">
        <div className="hero-container pageBannerPadding">
          <div className="row">
            <div className="col-md-7">
              <h1>Digital security with XCheck&trade;</h1>
              <div className="summary mt-6">
                <br />
                <h3>
                  <b>XCheck&trade;</b> brings you a elegant technology solution
                  that allows news publishers to brand their work and protect
                  from unauthorized distribution on internet.
                </h3>
              </div>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/img/products/badge.png"
                alt="badge"
                className="badgeSize"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-industries g-pt-70 g-pb-50--lg">
        <div className="container">
          <div className="row d-flex align-items-lg-center flex-wrap">
            <div className="col-md-12 col-lg-6 g-mb-50--md g-pr-60--lg">
              <img
                className="img-fluid wd-100"
                src="../assets/img/compliance/1.jpg"
                alt="Description"
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="text-mobile-finance g-ml-20 g-ml-0--sm">
                <div className="g-mb-20">
                  <div className="g-mb-40">
                    <h2 className="h1 title-color">Why Label Is Needed?</h2>
                  </div>
                  <p className="g-font-size-16 summary-font">
                    While internet users are overwhelmed with information across
                    various online platforms, they have little or no awareness
                    about the types of content they are exposed to. As the
                    article gets viral, it becomes difficult for an average
                    reader to trace the source of the article as most of the
                    time, the same piece of information may be found on multiple
                    portals with no attribution to the original source or the
                    author. Thus, readers are unable to trust news or gain
                    insights on the credibility of the author of that particular
                    article.
                  </p>
                  <p className="g-font-size-16 summary-font">
                    <b>XCheck</b> provides them option to cross check and trust
                    they read. For publishers, this badge is seal-of-trust and
                    gateway to revenues.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-white-industries">
        <div className="container g-pt-100 g-pb-70">
          <div className="g-mb-50">
            <h4>
              XCheck&trade; manages internet news registry in form of Open
              Source Public News Register&trade; to facilitate transparency,
              protection, verifiability and unlock revenues.
            </h4>
          </div>
          <div className="g-mt-minus-30 g-mt-0--md">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Internet Protection
                    </p>
                    <div className="hr">
                      <hr />
                    </div>
                    <p className="g-font-size-16 text-epigraphy">
                      XCheck label published with articles allows publisher to
                      protect work on internet
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Enabling Verifiability
                    </p>
                    <div className="hr">
                      <hr />
                    </div>
                    <p className="g-font-size-16 text-epigraphy">
                      Readers gain confidence when they are able to cross-check
                      associated information.
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">
                      Revenue Generation
                    </p>
                    <div className="hr">
                      <hr />
                    </div>
                    <p className="g-font-size-16 text-epigraphy">
                      Protected brand and articles from copy-paste directly
                      increased the revenue generation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <img
                  className="img-fluid wd-150"
                  src="../assets/img/compliance/2.jpg"
                  alt="Description"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </React.Fragment>
);

export default Xchecklabels;
