/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const FAQ = () => (
  <React.Fragment>
    <section className="section-faq">
      <div className="container g-pt-100 g-pb-70">
        <div className="g-mb-50">
          <img
            className="square-img"
            src="..\assets\img\title-square.svg"
            alt="faq"
          />
          <h2 className="h1 title-color">Frequently Asked Questions</h2>
        </div>
        <div className="g-mt-minus-30 g-mt-0--md">
          <div className="row">
            <div className="col-md-12 col-lg-6 g-pr-60--lg">
              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq pb-3">
                    What is Public News Register&trade;?
                  </p>
                  <ul>
                    <li>
                      XCheck is creation open source public news register with
                      distributed ledger technology for industry to collaborate
                      and work for the common cause of journalism. Member
                      organization get to create entry in public news register
                      for protection, distribution, syndication and industry
                      fact checking.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq pb-3">
                    Why Public News Registerr&trade; is Open Source?
                  </p>
                  <ul>
                    <li>
                      Open source is by people for the people. We are ardent
                      supporter of source technologies as they unlock human
                      potential by providing same set of playing field to
                      everyone.{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    How is XCheck different from other websites combating
                    misinformation and fake news?
                  </p>
                  <ul>
                    <li>
                      Fake News is a very subjective problem and technology can
                      never provide an absolute solution to it.
                    </li>
                    <li>
                      At XCheck, technology is an enabler of the framework
                      designed to provide fundamental and systemic solutions to
                      the long-term goal of fighting fake news.
                    </li>
                    <li>
                      Our patent pending framework brings together different
                      stakeholders through a participatory approach, open-source
                      algorithms, blockchain technology, analytics and
                      low-overhead human-in-the-loop to provide a scalable
                      solution to combat fake news.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    How is article source-tracing is important for dealing with
                    fake news?
                  </p>
                  <ul>
                    <li>
                      The Internet provides us with the freedom of expression
                      and auditability of any information or news.
                    </li>
                    <li>
                      To know the credibility of an article, it is important to
                      know the origin of the article and further check the
                      credibility of the platform that enabled further such
                      information.
                    </li>
                    <li>
                      XCheck enables source-tracing by tracking the location of
                      an article, time and other metrics associated with an
                      online article and thus, reach the source of fake news
                      rather than content of it.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    {" "}
                    What makes XCheck a trust-worthy organisation for securing
                    online articles?
                  </p>

                  <ul>
                    <li>
                      Approach used by XCheck is not new. There are few other
                      companies that use distributed ledger technology to ensure
                      auditability and cross-verification of online articles.
                    </li>
                    <li>
                      Taking innovation forward, XCheck has created a
                      proprietary solution that enables articles to be protected
                      on the blockchain and gathering intelligence from the
                      internet for published articles.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    Does XCheck protect registered publishers’ articles from
                    manipulation too or merely detect reproduction ?
                  </p>
                  <ul>
                    <li>
                      XCheck takes care of manipulation and illicit reproduction
                      both. For fighting manipulation, it makes changes visible
                      to the general public by allowing them to compare various
                      versions whether it be one edited by a journalist, image
                      editor or anyone else.
                    </li>
                    <li>
                      For detecting reproduction, it regularly crawls the
                      internet and identifies websites that are publishing the
                      same content without authorization. When someone
                      reproduces an article with XCheck badge online, the system
                      instantly detects and flags.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 g-pl-60--lg">
              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    Why would credible media organisations require XCheck, when
                    their websites are credible sources of journalists’ content?
                    Does this limit the scope?
                  </p>
                  <ul>
                    <li>
                      Credible media organizations are hit with problems of
                      plagiarism, fake news distribution and diversion of
                      traffic to unreliables sources.
                    </li>
                    <li>
                      Apart from making readers more confident and aware, XCheck
                      enables revenue protection of journalists by identifying
                      fake news and taking responsibility for legal action in
                      case of website misuse.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    What are the revenue generation methods of XCheck?
                  </p>
                  <ul>
                    <li>
                      XCheck makes revenue from managing Public News
                      Register&trade; and providing technology services. It
                      relies on community of developers to further Open Source
                      technology and organizations sponsorship to solve issues
                      for public good.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    Does XCheck cater to sponsored content? If yes, how can it
                    fill the gaps for potential misleading content?
                  </p>
                  <ul>
                    <li>
                      XCheck takes a hands-off approach to content within any
                      article. If content is sponsored then, it relies on
                      journalist or media publication for disclosure to readers.
                      Alternatively, if XCheck automated systems discovers
                      content to be sponsored and not disclosed then the system
                      penalizes the credibility score of journalists and media
                      organizations.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    What stops someone from reproducing, manipulating any
                    article even if it is published with an XCheck badge?
                  </p>
                  <ul>
                    <li>
                      XCheck labels are proprietary therefore, until all
                      conditions are fulfilled, neither journalists nor media
                      organizations have the right to use it. XCheck crawls the
                      internet on a regular basis, detects illicit use and
                      collect digital evidence for action.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    Given multimedia convergence, does XCheck work for
                    standalone multimedia projects and not just text-based,
                    which are also susceptible to manipulation?
                  </p>
                  <ul>
                    <li>
                      Currently, XCheck is limited to handling text and images.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="item-check">
                <div className="text-check">
                  <p className="title-faq">
                    Does XCheck have methods to testify credibility of the
                    publishers/media organisations who register themselves for
                    digital security?
                  </p>
                  <ul>
                    <li>
                      XCheck conducts exhaustive diligence on various parameters
                      before onboarding publisher/media organizations which
                      includes organization details, registration details and
                      other relevant information to ensure transparency.
                    </li>

                    <li>
                      XCheck also plans to expand the team to include persons
                      with required editorial capabilities to check the
                      credibility of registered publishers.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default FAQ;
