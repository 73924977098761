import React from "react";
import HomeLayouts from "../layouts/HomeLayouts";
import Banner from "../sections/Banner";
import Products from "../sections/Products";
import Solutions from "../sections/Solutions";
import Others from "../sections/Others";

const Home = () => (
  <HomeLayouts>
    <main>
      <Banner />
      <Products />
      <Others />
      <Solutions />
    </main>
  </HomeLayouts>
);

export default Home;
