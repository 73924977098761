import React from "react";

const Footer = () => (
  <React.Fragment>
    <div className="g-bg-black-opacity-0_9 g-color-white-opacity-0_8 g-py-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 g-mb-40 g-mb-0--lg">
            <a href="index.htm" className="navbar-brand g-mb-20">
              <img src="/xcheck-white.png" alt="xchcek" width={120} />
            </a>

            <div style={{ lineHeight: 1.8 }}>
              <p className="color-grey-footer">
                Cross Check manages Public News Register&trade; of media
                industry to protect news supply chain, valuable assets, enable
                cross verification and further digital journalism.
              </p>
            </div>
            <ul className="list-inline">
              <li
                className="list-inline-item g-mx-10"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Linkedin"
              >
                <a
                  href="https://linkedin.com/company/xcheckit"
                  className="g-color-white h5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin" />
                </a>
              </li>

              <li
                className="list-inline-item g-mx-10"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Twitter"
              >
                <a
                  href="https://twitter.com/xcheckit"
                  className="g-color-white h5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li
                className="list-inline-item g-mx-10"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Facebook"
              >
                <a
                  href="https://facebook.com/xcheckit"
                  className="g-color-white h5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li
                className="list-inline-item g-mx-10"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Instagram"
              >
                <a
                  href="https://instagram.com/xcheckit"
                  className="g-color-white h5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-instagram" />
                </a>
              </li>
              <li
                className="list-inline-item g-mx-10"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Angel List"
              >
                <a
                  href="https://angel.co/"
                  className="g-color-white h5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-angellist" />
                </a>
              </li>
              <li
                className="list-inline-item g-mx-10"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="FAQ"
              >
                <a
                  href="https://xcheck.tawk.help"
                  className="g-color-white h5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-question-circle-o" />
                </a>
              </li>
              <li
                className="list-inline-item g-mx-10"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Email"
              >
                <a
                  href="mailto:info@xcheck.org"
                  className="g-color-white h5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-envelope" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 g-mb-40 g-mb-0--lg">
            <nav className="text-uppercase1">
              <ul className="list-unstyled g-mt-minus-10 mb-0">
                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/about"
                    >
                      About
                    </a>
                  </h4>
                </li>

                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/xchecklabel"
                    >
                      XCheck
                    </a>
                  </h4>
                </li>
                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/team"
                    >
                      People
                    </a>
                  </h4>
                </li>

                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/"
                    >
                      Advisory
                    </a>
                  </h4>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-lg-2 col-md-6">
            <nav className="text-uppercase1">
              <ul className="list-unstyled g-mt-minus-10 mb-0">
                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/publisher"
                    >
                      Publisher
                    </a>
                  </h4>
                </li>

                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/newsagency"
                    >
                      News Agency
                    </a>
                  </h4>
                </li>

                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="https://xcheck.tawk.help/"
                    >
                      Q&amp;A
                    </a>
                  </h4>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-lg-2 col-md-6">
            <nav className="text-uppercase1">
              <ul className="list-unstyled g-mt-minus-10 mb-0">
                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/termsofservice"
                    >
                      Terms of Service
                    </a>
                  </h4>
                </li>
                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/privacypolicy"
                    >
                      Privacy
                    </a>
                  </h4>
                </li>
                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/"
                    >
                      Documentation
                    </a>
                  </h4>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-lg-2 col-md-6">
            <nav className="text-uppercase1">
              <ul className="list-unstyled g-mt-minus-10 mb-0">
                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/"
                    >
                      Career
                    </a>
                  </h4>
                </li>
                <li className="g-pos-rel  g-brd-white-opacity-0_1 g-py-10">
                  <h4 className="h6 g-pr-20 mb-0">
                    <a
                      className="g-color-white-opacity-0_8 g-color-white--hover"
                      href="/contact"
                    >
                      Contact
                    </a>
                  </h4>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <footer className="g-bg-gray-dark-v1 g-color-white-opacity-0_8 g-py-20">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center g-mb-10 g-mb-0--md">
            {/* <ul className="u-list-inline">
              <li className="list-inline-item g-mx-10 g-font-size-12">India</li>
            </ul> */}
          </div>
        </div>
      </div>
    </footer>
  </React.Fragment>
);

export default Footer;
