/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Advisory = () => (
  <React.Fragment>
    <main>
      <div className="background-grey">
        <section className="container g-pt-150">
          <div className="g-mb-50">
            <h2 className="h1 title-color">Advisory</h2>
          </div>

          <section className="section-industries">
            <div className="container">
              <div className="row d-flex align-items-lg-center flex-wrap">
                <div className="col-md-12 col-lg-4 g-mb-50--md pl-0 pr-0">
                  <img
                    className="img-fluid wd-100"
                    src="../assets/img/compliance/dummy.png"
                    alt=" Description"
                  />
                </div>
                <div className="col-md-12 col-lg-8">
                  <div className="text-mobile-finance g-ml-20 g-ml-0--sm">
                    <div className="g-mb-20">
                      <div className="g-mb-40">
                        <h2>We are looking for Advisor</h2>
                      </div>
                      <p className="g-font-size-16 summary-font">
                        Love what we do? If you're interested in helping us
                        protect journalism then please, express your interest at
                        gawrav@xcheck.org
                      </p>
                      <p className="g-font-size-16 summary-font">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </main>
  </React.Fragment>
);

export default Advisory;
