/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Team = () => (
  <React.Fragment>
    <main>
      <div className="background-grey">
        <section className="container g-pt-150">
          <div className="g-mb-50">
            <h2 className="h1 title-color">Team</h2>
          </div>
          <section className="container g-pb-50 without-padding-about-us">
            <div className="row figures">
              <div className="col-lg-3 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="https://res.cloudinary.com/xcheck/image/upload/v1610891137/team/gawrav.jpg"
                      alt="Image Description"
                    />
                    <figcaption className="u-block-hover__additional--fade g-bg-primary-opacity-0_9 g-pa-30">
                      <div className="u-block-hover__additional--fade u-block-hover__additional--fade-down g-flex-middle">
                        <ul className="list-inline text-center g-flex-middle-item">
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="https://www.linkedin.com/in/gawravmehta/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="https://www.twitter.com/gewgawrav"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </figcaption>
                  </figure>
                  <div className="g-bg-white g-py-25 g-px-15--xl padding-sides">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">Gaurav Mehta</h4>
                      <em className="d-block u-info-v1-2__item g-font-style-normal g-font-size-14">
                        Co-founder
                      </em>
                    </div>
                    <p>
                      8+ years of information technology industry experience.
                      B.Tech, Metallurgy, Masters in Startup. TEDx speaker,
                      blockchain enthusiast and startup practitioner.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="https://res.cloudinary.com/xcheck/image/upload/v1610891137/team/kewal.jpg"
                      alt="Image Description"
                    />
                    <figcaption className="u-block-hover__additional--fade g-bg-primary-opacity-0_9 g-pa-30">
                      <div className="u-block-hover__additional--fade u-block-hover__additional--fade-down g-flex-middle">
                        <ul className="list-inline text-center g-flex-middle-item">
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </figcaption>
                  </figure>
                  <div className="g-bg-white g-py-25 g-px-15--xl padding-sides">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">
                        Kewal Krishna Bhoi
                      </h4>
                      <em className="d-block u-info-v1-2__item g-font-style-normal g-font-size-14">
                        Co-founder
                      </em>
                    </div>
                    <p>
                      7+ years of industry experience ranging from HPCL to
                      BNY-Mellon. Expert in product management, finance and
                      planning. MBA in Finance &amp; Operations from IIM-R
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="https://res.cloudinary.com/xcheck/image/upload/v1610891137/team/shaurya.jpg"
                      alt="Image Description"
                    />
                    <figcaption className="u-block-hover__additional--fade g-bg-primary-opacity-0_9 g-pa-30">
                      <div className="u-block-hover__additional--fade u-block-hover__additional--fade-down g-flex-middle">
                        <ul className="list-inline text-center g-flex-middle-item">
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </figcaption>
                  </figure>
                  <div className="g-bg-white g-py-25 g-px-15--xl padding-sides">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">
                        Shauryaditya Singh
                      </h4>
                      <em className="d-block u-info-v1-2__item g-font-style-normal g-font-size-14">
                        Co-founder
                      </em>
                    </div>
                    <p>
                      {" "}
                      4+ years of business experience in information technology
                      with knack of people management, logics and operations.
                      Computer Science graduate &amp; MBA from MATS University
                      domain.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="https://res.cloudinary.com/xcheck/image/upload/v1610891137/team/manish.jpg"
                      alt="Image Description"
                    />
                    <figcaption className="u-block-hover__additional--fade g-bg-primary-opacity-0_9 g-pa-30">
                      <div className="u-block-hover__additional--fade u-block-hover__additional--fade-down g-flex-middle">
                        <ul className="list-inline text-center g-flex-middle-item">
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="https://www.linkedin.com/in/gawravmehta/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="https://www.twitter.com/gewgawrav"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </figcaption>
                  </figure>
                  <div className="g-bg-white g-py-25 g-px-15--xl padding-sides">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">Manish Sahu</h4>
                      <em className="d-block u-info-v1-2__item g-font-style-normal g-font-size-14">
                        Development Intern
                      </em>
                    </div>
                    <p>
                      Full stack development intern with interest and
                      development knowledge of React, Javascript, Node.Js,
                      GraphQL, ELK, Docker and Cloud Services.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="https://res.cloudinary.com/xcheck/image/upload/v1610891137/team/dhairya.jpg"
                      alt="Image Description"
                    />
                    <figcaption className="u-block-hover__additional--fade g-bg-primary-opacity-0_9 g-pa-30">
                      <div className="u-block-hover__additional--fade u-block-hover__additional--fade-down g-flex-middle">
                        <ul className="list-inline text-center g-flex-middle-item">
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="https://www.linkedin.com/in/gawravmehta/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                          <li className="list-inline-item align-middle g-mx-3">
                            <a
                              className="u-icon-v2 u-icon-size--sm g-font-size-default g-color-white g-bg-white g-bg-white-opacity-0_7--hover rounded-circle"
                              href="https://www.twitter.com/gewgawrav"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </figcaption>
                  </figure>
                  <div className="g-bg-white g-py-25 g-px-15--xl padding-sides">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">Dhairya Mehta</h4>
                      <em className="d-block u-info-v1-2__item g-font-style-normal g-font-size-14">
                        Marketing Intern
                      </em>
                    </div>
                    <p>
                      {" "}
                      Young, passionate and active learner of Digital Marketing
                      tactics. Winner of Google Digital Marketing Challenge.
                      College student and intern completing degree in
                      electronics from IIIT-B{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
      {/* <section className="container g-pt-50 g-pb-50">
        <div className="g-mb-20 without">
          <img
            className="square-img"
            src="../assets/img/title-square.svg"
            alt="desc"
          />
          <h2 className="h1 color-black-grey title-grey">Contactanos</h2>
        </div>
        <div className="row g-pt-30">
          <div className="col-md-8 g-mb-60">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.316935961273!2d-58.37551988421125!3d-34.596146380461335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccaca945047bd%3A0xc53b1f6a26a8d582!2sMarcelo+Torcuato+de+Alvear+405%2C+C1058AAC+CABA!5e0!3m2!1ses!2sar!4v1532451452291"
              className="js-g-map embed-responsive embed-responsive-21by9 g-height-500"
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen
            />
          </div>
          <div className="col-md-4">
            <div className="mb-5 g-pt-70">
              <h2 className="h1 color-black-grey g-line-height-0_8 g-font-weight-300">
                xchcek
              </h2>
              <h2 className="h1 color-black-grey g-line-height-0_8 g-font-weight-300">
                Cosentio Inc.
              </h2>
            </div>
            <div className="mb-4">
              <h2 className="h6 g-color-gray-dark-v2 g-font-weight-600">
                Dirección
              </h2>
              <p className="address">
                Marcelo T. de Alvear 405, #2
                <br />
                Ciudad de Buenos Aires (C1058AAC)
                <br />
                Argentina.
              </p>
            </div>
            <div className="mb-4">
              <h2 className="h6 g-color-gray-dark-v2 g-font-weight-600">
                Escribinos
              </h2>
              <a
                className="color-blue"
                href="mailto:help@xchcek.co"
                target="_blank"
                rel="noopener noreferrer"
              >
                help@xchcek.co
              </a>
            </div>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a
                  className="u-icon-v1 g-color-gray-dark-v5 g-bg-gray-light-v5 g-color-white--hover g-bg-primary--hover rounded-circle color-icon"
                  href="https://www.facebook.com/xchcekCo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="g-font-size-default fa fa-facebook" />
                </a>
              </li>
              <li className="list-inline-item g-mx-4">
                <a
                  className="u-icon-v1 g-color-gray-dark-v5 g-bg-gray-light-v5 g-color-white--hover g-bg-primary--hover rounded-circle color-icon"
                  href="https://twitter.com/xchcekCo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="g-font-size-default fa fa-twitter" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section> */}
    </main>
  </React.Fragment>
);

export default Team;
