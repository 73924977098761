import React from "react";

const Journalist = () => (
  <React.Fragment>
    <div>
      <section className="container">
        <div className="hero-container pageBannerPadding">
          <div className="row">
            <div className="col-md-12">
              <div className="w-60 mx-auto">
                <h1 className="text-center newsText">
                  What jounalists do, no one else does.
                </h1>
                <div className="summary mt-5">
                  <p
                    className="g-font-size-20
                summary-font text-center lineHeight
                "
                  >
                    <i>Protection, Recognition</i> and <i>Regulating</i> the
                    online usage of journalist's work is fundamental to
                    sustainable journalism. Publisher's are empowered when work
                    is protected, due recognition is provided and personal brand
                    is visible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-industries g-pt-70 g-pb-50--lg">
        <div className="container">
          <div className="row d-flex align-items-lg-center flex-wrap">
            <div className="col-md-12 col-lg-6 g-mb-50--md g-pr-60--lg">
              <img
                className="img-fluid wd-100"
                src="../assets/img/compliance/1.jpg"
                alt=" Description"
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="text-mobile-finance g-ml-20 g-ml-0--sm">
                <div className="g-mb-20">
                  <div className="g-mb-40">
                    <h2 className="h1 title-color">Journalism over internet</h2>
                  </div>
                  <p className="g-font-size-16 summary-font">
                    Piece of journalism is akin to piece of art. Artists use
                    paint brush, colours and canvas to create art while
                    jounralist uses network to gather information, invest time
                    to create article. Only different is one is paid well
                    because art is protected and other is unpaid because, its
                    easy to copy-paste journalist work. Price discovery of news
                    is still done in traditional way.
                  </p>
                  <br />
                  <a
                    className="u-link-v5 g-cursor-pointer product-btn-green"
                    href="\"
                  >
                    Book Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-white-industries">
        <div className="container g-pt-100 g-pb-70">
          <div className="g-mb-50">
            <h2 className="h1 title-color text-center">
              How journalists leverage XCheck for growth?{" "}
            </h2>
          </div>
          <div className="g-mt-minus-30 g-mt-0--md">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="item-check">
                  <div className="text-check">
                    <p className="g-font-size-16 text-epigraphy">
                      XCheck provide free solution for individual and freelance
                      jounralists to manage their
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Protection</p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Some line will come here for public to read and understand
                      protection.
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Distribution</p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Government departments can allow XCheck to be the first
                      step in the communication process and news media
                      organisations interested in publishing that particular
                      press release can then claim a copy for free for
                      publishing it on their online platforms.
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Revenue</p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Some line will come here for general public to read and
                      understand the value proposition.
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Source Tracing</p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      With its source-tracing method, XCheck allows the
                      government to digitally secure published information,
                      manage rights, regulate its usage, curb fake news and use
                      the analytics to adopt effective communication strategies.
                    </p>
                  </div>
                </div>

                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Manipulation</p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Protect the public communication from mala fide interests
                      and manipulations as altered versions are available in the
                      public register for auditing.
                    </p>
                  </div>
                </div>

                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Intelligence</p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Collate data points on news engagements, focus on subjects
                      that matter to the masses, shape better policies that are
                      driven by public concerns, and identify the best suitable
                      approach for campaign and that bring desired public
                      engagement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </React.Fragment>
);

export default Journalist;
