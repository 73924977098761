import React, { Component } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import getdatabase from "../config/fire";
// import Swal from "sweetalert2";

const db = getdatabase();
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      mobile: "",
      confPassword: "",
      loading: true,
    };
  }

  signUp = (e) => {
    e.preventDefault();
    console.log("clicked");
    const { name, email, password, confPassword, mobile } = this.state;

    console.log(password);
    console.log(confPassword);

    if (name.length === "") {
      NotificationManager.error("", "Please enter name");
      return;
    }

    if (email.length === "") {
      NotificationManager.error("", "Please enter email");
      return;
    }
    if (mobile.length === "") {
      NotificationManager.error("", "Please Enter Mobile Number");
      return;
    }

    if (password === "") {
      NotificationManager.error("", "Please enter password");
      return;
    }
    if (password.length < 6) {
      NotificationManager.error("", "Minimum 6 characters");
      return;
    }
    if (confPassword === "") {
      NotificationManager.error("", "Please enter confirm password");
      return;
    }
    if (confPassword.length < 6) {
      NotificationManager.error("", "Please enter confirm password");
      return;
    }
    if (password !== confPassword) {
      NotificationManager.error("", "Password Mismatch");
      return;
    }
    db.collection("users")
      .add({
        name: this.state.name,
        mobile: this.state.mobile,
        email: this.state.email,
        password: this.state.password,
        confPassword: this.state.confPassword,
      })
      .then(function () {
        NotificationManager.success("", "Registered Successfully");
        // Swal.fire("Registered Successfully", "success");
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    this.setState({
      name: "",
      mobile: "",
      email: "",
      password: "",
      confPassword: "",
    });
    db.collection("users")
      .add({
        name: this.state.name,
        mobile: this.state.mobile,
        email: this.state.email,
        password: this.state.password,
        confPassword: this.state.confPassword,
      })
      .then(function () {
        NotificationManager.success("", "Registered Successfully");
        // Swal.fire("Registered Successfully", "success");
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    this.setState({
      name: "",
      mobile: "",
      email: "",
      password: "",
      confPassword: "",
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { name, email, password, mobile, confPassword } = this.state;
    return (
      <React.Fragment>
        <div
          className="container"
          style={{ paddingTop: "10rem", paddingBottom: "10rem" }}
        >
          <form className="w-50 mx-auto">
            <div className="form-group">
              <label>Name</label>
              <input
                name="name"
                type="text"
                className="form-control"
                value={name}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input
                name="email"
                type="email"
                className="form-control"
                value={email}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Mobile</label>
              <input
                name="mobile"
                type="number"
                className="form-control"
                value={mobile}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Password</label>
              <input
                name="password"
                type="password"
                className="form-control"
                value={password}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Confirm Password</label>
              <input
                name="confPassword"
                type="password"
                className="form-control"
                value={confPassword}
                onChange={this.handleChange}
                required
              />
            </div>

            <button
              type="submit"
              className="btn btn-md btn-dark float-right"
              onClick={this.signUp}
            >
              Submit
            </button>
          </form>
        </div>
        <NotificationContainer />
      </React.Fragment>
    );
  }
}

export default Register;
