/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Technology = () => (
  <React.Fragment>
    <div>
      <section className="container">
        <div className="hero-container pageBannerPadding">
          <div className="row">
            <div className="col-md-7">
              <h2>Simple, Sensible &amp; Smart</h2>
              <div className="summary mt-5">
                <p className="g-font-size-17 summary-font">
                  XCheck regularly collects data from every corner of internet.
                  We constantly expand our list of monitoring domains and cross
                  verify with Public News Register&trade; to generate
                  intelligence, perform analysis and identify anomaly.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <img src="/simple-image.png" alt="badge" className="badgeSize" />
            </div>
          </div>
        </div>
      </section>
      <section className="section-products">
        <section id="feature-teamwork" className="background-grey-products">
          <div className="container g-py-100--md g-pt-50">
            <div className="row d-flex align-items-lg-center flex-wrap">
              <div className="col-md-6 col-lg-6">
                <img
                  className="img-fluid"
                  src="../assets/img/tech1.png"
                  alt="Image Description"
                />
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="height-industries g-ml-30--lg g-mt-minus-30 g-mt-0--md">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 block-text">
                      <div className="g-mb-20">
                        <img
                          className="square-img"
                          src="../assets/img/title-square.svg"
                          alt="svg_icon"
                        />
                        <h2 className="title-color">Technology Transparency</h2>
                      </div>
                      <p className="summary-font">Open Source codes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container g-py-100--md g-pt-50">
            <div className="row d-flex justify-content-between align-items-lg-center flex-wrap">
              <div className="col-md-6 col-lg-6 flex-md-unordered">
                <div className="g-brd-10 g-brd-white rounded">
                  <img
                    className="img-fluid"
                    src="../assets/img/tech2.png"
                    alt="Image Description"
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 flex-md-first">
                <div className="height-industries g-ml-30--lg g-mt-minus-30 g-mt-0--md">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 block-text">
                      <div className="g-mb-20">
                        <img
                          className="square-img"
                          src="../assets/img/title-square.svg"
                          alt="svg_icon"
                        />
                        <h2 className="title-color">Structure &amp; Analyse</h2>
                      </div>
                      <p className="summary-font">
                        YYLorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="background-grey-products">
          <div className="container g-py-100--md g-pt-50">
            <div className="row d-flex align-items-lg-center flex-wrap">
              <div className="col-md-6 col-lg-6">
                <img
                  className="img-fluid"
                  src="../assets/img/tech3.png"
                  alt="Image Description"
                />
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="height-industries g-ml-30--lg g-mt-minus-30 g-mt-0--md">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 block-text">
                      <div className="g-mb-20">
                        <img
                          className="square-img"
                          src="../assets/img/title-square.svg"
                          alt="svg_icon"
                        />
                        <h2 className="title-color">
                          Disinformation Investigations
                        </h2>
                      </div>
                      <p className="summary-font">
                        XXLorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </React.Fragment>
);

export default Technology;
