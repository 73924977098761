import React from "react";

const Banner = () => (
  <React.Fragment>
    <section className="hero-image">
      <div className="hero-container">
        <div className="container">
          <h1 className="hero-title text-dark">Protect News from bad actors on Internet</h1>
          <div className="summary">
            <h2
              className="hero-text"
              style={{ display: "flex", flexWrap: "wrap",}}
            >
              In few minutes, news published on internet is plagiarized, manipulated and distributed by unauthorized websites hurting trust &amp; revenue. We protect both.
            </h2>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default Banner;
