import React from "react";

const Privacypolicy = () => (
  <React.Fragment>
    <div className="container" style={{ ...myStyles.container, ...myStyles.p }}>
      <h1>Privacy policy</h1>
      <p>
        This Privacy Statement reflects XCheck, Inc.’s and its subsidiaries’ and
        affiliated companies’, including and Xcheck India Pvt. Ltd, (“XCheck,”
        “we,” “our,” and “us”) commitment to privacy and data protection.
      </p>
      <p>
        This Privacy Statement describes how we collect and process information
        about visitors to our websites (the “Site”), users of our mobile
        applications and individuals who use XCheck’s data analytics platform
        (collectively the “Application Services”).
      </p>
      <p>
        This Privacy Statement doesn’t apply to individuals whose information
        may be collected by XCheck’s customers during their use of XCheck’s
        Application Services.
      </p>

      <subhead className="sub-heading">SUMMARY OF KEY POINTS</subhead>

      <p>
        We collect and use personal information and personal data from visitors
        to our Site, and users of our Application Services to provide XCheck’s
        Application Services, to ensure those Services and our marketing
        materials are relevant and secure, and to conduct our business.
      </p>
      <p>
        This Statement only applies to our collection and use of personal
        information and personal data, not to our customers’ use of XCheck’s
        software to collect information or data. If you have questions about how
        our customers use XCheck to collect information or data from your use of
        their services, you should contact them directly.
      </p>
      <p>
        XCheck collects personal information when you sign up for our
        Application Services, use our software, or share information with us. We
        also collect information about you using various technologies (including
        first- and third-party cookies, local browser storage, and other
        tracking technologies).
      </p>

      <p>
        XCheck uses the personal information and personal data you share or that
        we collect to provide our Application Services, to enhance our websites
        and mobile applications, to market XCheck’s Application Services to you,
        for security purposes, and to comply with applicable laws, regulations,
        and other legal requirements. You can learn more about our use of
        personal information and personal data here.
      </p>
      <p>
        XCheck does not sell the personal information or personal data we
        collect. We do share that information and data with select third
        parties, but only in connection with providing our Application Services
        to our customers or when we have a legal basis for such sharing.
      </p>
      <p>
        XCheck uses cookies and other tracking technologies to track your use of
        our websites, mobile applications, and your use of our Application
        Services. This Statement describes opportunities for controlling our use
        of these technologies.
      </p>
      <p>
        XCheck transfers personal information and personal data to the United
        States and other countries. These may be outside of the country or
        region you live in. We perform these transfers under certain model
        contractual provisions approved by the European Commission or by the
        Privacy Shield Framework.
      </p>
      <p>
        XCheck respects privacy rights, and we built our privacy compliance
        program with the European General Data Protection (the “GDPR”) and the
        California Consumer Privacy Act (the “CCPA”) firmly in mind.
      </p>
      <p>
        XCheck takes information security seriously, and its security controls
        are designed to safeguard personal information and personal data.
      </p>
      <p>
        XCheck has appointed a data protection officer, or “DPO.” You can
        contact our DPO at <span className="text-primary">info@xcheck.org</span>{" "}
        You can also contact our privacy program by writing to{" "}
        <span className="text-primary">info@xcheck.org</span>
      </p>

      <subhead className="sub-heading">
        APPLICATION OF OUR PRIVACY STATEMENT
      </subhead>

      <p>
        Our Privacy Statement applies to XCheck’s collection of information or
        data, and it is not intended to discuss the collection or use of
        personal information or personal data by a Customer’s use of the
        Application Services. It also does not apply to third-party websites,
        applications, or services reachable from our websites or mobile
        applications.
      </p>
      <p>
        XCheck collects information under the direction of its Customers in
        connection with its provision of the Application Services. Because a
        Customer’s collection or use of personal information is specific to
        their use and implementation of the Application Services, please consult
        a Customer’s privacy statement, privacy notice, or the written contract
        between a Customer and XCheck for the Application Services for more
        information. We do not have a direct relationship with our customers’
        customers.
      </p>
      <p>
        XCheck collects personal information when you sign up for our
        Application Services, use our software, or share information with us. We
        also collect information about you using various technologies (including
        first- and third-party cookies, local browser storage, and other
        tracking technologies).
      </p>

      <p>
        If you are a customer of one of our Customers and would like more
        information on their use of your information, please contact the
        Customer that you interact with directly. If you have further questions
        about the application of our Privacy Statement, please contact our
        privacy program at info@xcheck.org
      </p>

      <subhead className="sub-heading">INFORMATION WE COLLECT</subhead>
      <p>
        We collect information, including personal information or personal data,
        when you visit our Sites or use our Application Services. We collect
        this information when you fill out forms or engage with us directly. We
        also collect this information via cookies, pixel tags, and certain
        software designed to interact with your computer or mobile device.
      </p>

      <subhead className="sub-heading">Information you share with us</subhead>

      <p>
        If you choose to share your information with us, we will collect the
        following information:
      </p>

      <p>
        If you decide to create a XCheck application account to use our
        Application Services, you must enter your first and last name, email
        address, and password or otherwise enter similar information into forms
        or surveys on our Site or Applications.
      </p>

      <p>
        If you share information with us by entering it into your
        account–information like postal address, phone number, fax number,
        company name, and credit card payment information.
      </p>

      <p>
        If you tell us where you are (e.g., by allowing your mobile device to
        send us your location), we may store and use that information for
        internal analytics purposes.
      </p>
      <p>
        When you are asked to provide personal information or personal data, you
        may decline. And you may use application or device controls to prevent
        certain types of automatic data collection. But if you choose not to
        provide or allow information that is necessary for specific products or
        features, those products or features may not be available or function
        correctly.
      </p>
      <subhead className="sub-heading">
        Information collected via technology
      </subhead>
      <p>
        We automatically collect certain information via technology when you use
        the Site and Application Services:
      </p>

      <p>
        <b> IP Address, Location, Website Activity, and Browser Information</b>.
        When you visit our Site or use our Applications, some information is
        automatically collected. We collect information about the type and
        language of the operating system (“OS”) running on your device, your
        internet protocol address (“IP Address”), the browser type you used
        during your visit, and the website you visited just before ours. We also
        collect information about which web-elements or objects you interact
        with, metadata about your activity on the Site, changes in your user
        state, and the duration of your visit.
      </p>
      <p>
        <b>Mobile Application Services</b>. We may also collect information from
        your mobile device including, but not limited to, the first time the
        Application is opened, the mobile carrier, the device identifier, the
        type of device that is being used, if you have downloaded our
        Applications, and your usage activity while using the Application. This
        information is generally used to help us deliver the most relevant
        information to you and improve our
      </p>

      <p>
        Application Services and analytics platform. Also, in the event our
        Applications crash on your mobile device, we will receive information
        about your mobile device model and OS version, which allows us to
        identify and fix bugs and otherwise improve the performance of our
        Applications. We send you a push notification from time-to-time to
        update you about any events or promotions that we may be running. If you
        no longer wish to receive these types of communications, you may turn
        them off at the device level. To ensure you receive proper
        notifications, we will need to collect certain information about the
        device you are using, such as the OS and user identification
        information. We use mobile analytics software to allow us to better
        understand the functionality of our Mobile Software on your phone. This
        software may record information such as how often you use the
        Application, the events that occur within the Application, aggregated
        usage, performance data, and what app store you used to download the
        Application. We do not link the information we store within the
        analytics software to any personally identifiable information you submit
        within the mobile Application.
      </p>
      <p>
        <b>Cookies</b>. XCheck collects information about your use of our Sites
        and Application Services via cookies.
      </p>
      <subhead className="sub-heading">
        Information obtained from third-party sources
      </subhead>
      <p>
        When we collect information in connection with our business relationship
        with you, we may also purchase data about you from third parties. To be
        clear, we do not buy any data from any third parties in connection with
        our provision of the Application Services, and we never combine the
        purchased data with information about our customers’ end users or
        customers.
      </p>
      <p>
        We protect information obtained from third parties according to the
        practices described in this Statement, plus any additional restrictions
        imposed by the source of the data. These third-party sources include,
        for example:
      </p>

      <p>
        Applications and services, such as social networks that make users’
        information available to others;
      </p>
      <p>
        Service providers that help us determine your device’s location based on
        its IP Address to customize certain products to your physical location;
      </p>

      <p>
        Partners with which we offer co-branded services or engage in joint
        marketing activities; and
      </p>
      <p>
        Publicly available sources, such as open government databases or other
        information in the public domain.
      </p>
      <subhead className="sub-heading">
        Information created or generated
      </subhead>
      <p>
        We infer new information from other information we collect, including
        using automated means to make information about your likely preferences
        or other characteristics. For example, we infer your city, state, and
        country location based on your IP Address.
      </p>

      <subhead className="sub-heading">
        COOKIES AND TRACKING TECHNOLOGIES
      </subhead>
      <p>
        Cookies are small text files we send to individuals’ computers when they
        visit our Sites. Like nearly all websites, cookies make our Sites work
        more efficiently and effectively and help us improve our Site, the
        relevance of our marketing activities, and your experience while using
        the Site and Application Services.
      </p>
      <p>
        Some of the cookies we use may provide us with information about you.
        The text in a cookie often consists of a string of numbers and letters
        that uniquely identifies your computer, but it can contain other
        information as well.
      </p>

      <p>
        We use cookies to see which areas and features are popular and to count
        visits to our Site. Most web browsers are set to accept cookies by
        default. If you prefer, you can choose to set your browser to remove
        cookies or to reject cookies. If you are visiting our Site from the
        European Union, you will be asked to opt-in to receiving cookies.
      </p>

      <p>
        If you want to delete or refuse cookies, this could affect certain
        features or functionality of our Site and could remove cookies used to
        opt-out of tracking for the Application Services.
      </p>

      <p>XCheck uses cookies and similar technologies for:</p>

      <p>
        <u>
          {" "}
          Site Authentication, Identity and Access Management, Security, and to
          Conduct Its Business.
        </u>{" "}
        When you sign into the Site using your username and password, we store
        an encrypted cookie on your device. This cookie allows you to move from
        page to page within the Site without having to sign in again on each
        page. You can also save your sign-in information, so you do not have to
        sign in each time you return to the Site.
      </p>

      <p>
        <u>Marketing.</u> XCheck uses cookies and related technologies to
        measure your interest in XCheck’s products and services so it can market
        those products and services to you.
      </p>
      <p>
        <u>Performance.</u> XCheck uses cookies for load balancing to help
        ensure that websites remain up and running.
      </p>

      <p>
        <u>Social Media.</u> Portions of our Site include social media cookies
        or widgets, including those that enable users who are logged in to the
        social media service to share content via that service.
      </p>

      <p>
        <u>Storing Information for Purchases on the Site.</u> When you seek to
        purchase one of our Application Services plans, we store the data in a
        cookie to remember the products and information you have added for
        purchase.
      </p>

      <p>
        <u> Site Experience.</u> When you use or view certain content on the
        Site, XCheck will store a cookie to ensure that you do not see the same
        content again or to save your interest preferences.
      </p>

      <p>
        <u>Opt-Out Cookie.</u> When you opt-out of tracking on the Site, XCheck
        will store a cookie that will block other cookies from being stored.
      </p>
      <p>
        Some of the cookies we commonly use are listed below. This list is not
        exhaustive, but it is intended to illustrate the main reasons we
        typically set cookies. If you visit one of our sites, the Site may set
        some or all of the following cookies:
      </p>

      <p>
        <u> IRB, Insights, or MP:</u> We use these cookies for Site analytics
        and operational purposes.
      </p>

      <p>
        <u>SessionID</u>: Helps to authenticate you when you sign in to your
        account.
      </p>
      <p>
        Certain third-parties may also set cookies on your computer or device
        when you visit our Sites. In some cases, that is because we have hired
        the third party to provide services on our behalf, such as payment
        processing for credit card billing. In other cases, it is because our
        web pages may contain content or ads from third parties, such as videos,
        news content, or ads delivered by other ad networks. Because your
        browser connects to those third parties’ web servers to retrieve that
        content, those third parties set and read their cookies on your device
        and may collect information about your online activities across websites
        or online services. To control cookies from those third parties, visit
        their sites. If you wish to opt-out of interest-based advertising, click
        here (or if located in the European Union, click here). Please note you
        will continue to receive generic ads. These third-party cookies include:
      </p>
      <p>Google</p>
      <p>LinkedIn</p>
      <p>Twitter</p>
      <p>
        We also use “pixel tags” (also referred to as clear gifs, web beacons,
        or web bugs). Pixel tags are tiny graphic images with a unique
        identifier, similar in function to cookies (as noted below), that are
        used to track the online movements of web users. We embed these on our
        web pages but do not store them locally on your computer or phone. Pixel
        tags also allow us to see if emails we send are opened. We do this to
        ensure that we are sending only messages that are of interest to our
        Customers. We may use this information to reduce or eliminate messages
        sent to a user. We do not tie the information gathered by pixel tags to
        our users’ personal information.
      </p>
      <p>Browser Local Storage</p>
      <p>
        We also store data locally on your device to enhance the user experience
        on our Site. For example, browser local storage allows us to save the
        state of your current page so that when you return at a later time or
        refresh the browser, the page will remain the same. We also use browser
        local storage to display “recently viewed items” or to mark your place
        in a tutorial, video, tooltip, or onboarding flow.
      </p>
      <subhead className="sub-heading">
        OUR USE OF COLLECTED INFORMATION
      </subhead>
      <p>
        We use the personal information we collect for purposes described in
        this Privacy Statement or as otherwise disclosed to you. In general, we
        use the information we collect primarily to communicate with you, to
        market, provide, maintain, protect, and improve our current Application
        Services, and to develop new or enhanced versions of those Application
        Services.
      </p>

      <p> Here is a list of how we use your information:</p>
      <p>
        Facilitate the creation of and securing of your account on our network;
      </p>
      <p>Identify you as a user in our Application Services;</p>
      <p>
        Improve our Application Services, Site and how we operate our business;
      </p>
      <p>
        Understand and enhance your experience using our Site and Application
        Services;
      </p>
      <p>
        Provide and deliver products and services, including the Application
        Services, that you request, including securing, troubleshooting, and
        personalizing those products;
      </p>
      <p>
        Respond to your comments or questions and for our Support team to
        provide service;
      </p>

      <p>
        {" "}
        Send you related information, including confirmations, invoices,
        technical notices, updates, security alerts, and support and
        administrative messages;
      </p>
      <p>
        Communicate with you about promotions, upcoming events and news about
        products and services offered by XCheck and our selected partners, and
        for other marketing purposes of XCheck;
      </p>
      <p>
        Link or combine it with additional information we get from third
        parties, to help understand your needs and provide you with better
        service, as described under “Profiling” below; and
      </p>
      <p>
        Protect, investigate, and deter against fraudulent, unauthorized, or
        illegal activity.
      </p>
      <p>
        Creation of De-Identified Data. We may create de-identified data records
        sourced or extracted from data connected to or associated with personal
        information by excluding information (such as your name) that makes the
        data personally identifiable to you. We use this de-identified data to
        analyze request and usage patterns so that we may enhance the content of
        our Application Services and improve our Application Services and Site
        navigation. We reserve the right to use de-identified data for any
        purpose and disclose de-identified data to third parties in our sole
        discretion where permitted by applicable law.
      </p>
      <p>
        Profiling. When you interact with our Site by completing a form (such as
        a request for us to contact you, creation of an Account, request for
        information on pricing, or downloading content), we will collect your
        personal information and analyze your information to build individual
        profiles. We use third-party tools to help us make the profile by
        providing information about your business (like, company size and
        industry, for example). These profiles will be used to help us predict
        future interest in our Application Services. We base our profiling on
        your activity on the Site, and we update your profile in real-time based
        on your use of the Site.
      </p>

      <subhead className="sub-heading">12. Proprietary Rights Notice.</subhead>

      <p>
        We share personal information with your consent or as necessary to
        complete your transactions or provide the products you have requested or
        authorized. For example, when you provide payment information to make a
        purchase, we will share that information with banks and other entities
        as necessary for payment processing, fraud prevention, credit risk
        reduction, or other related financial services. We also share personal
        information as follows:
      </p>
      <p>
        Affiliates and Subsidiaries. Our subsidiaries and affiliates share
        standard information systems and process information as needed to
        provide our products and operate our business.
      </p>
      <p>
        Third-Party Service Providers. With third parties who work on our behalf
        for the purposes described in this Privacy Statement, provided that such
        third parties agree to provide at least the same level of privacy
        protection as required by this Privacy Statement. These services may
        include providing cloud computing infrastructure, website crash
        reporting, and providing customer support or account administration.{" "}
      </p>
      <p>
        Lawful Requests. To comply with laws or to respond to lawful requests
        and legal process, provided that XCheck will endeavor to notify you,
        where practical and permitted by applicable law or regulation, if XCheck
        has received a lawful request for your information.
      </p>
      <p>
        Protection of Rights and Property. To protect the rights and property of
        XCheck, our agents, customers, and others, including to enforce our
        agreements and policies.
      </p>
      <p>
        Emergency. In an emergency, including to protect the personal safety of
        any person.
      </p>
      <p>
        Corporate Restructuring. For a corporate transaction (or negotiation of
        a corporate transaction) involving sale or transfer of all or a part of
        our business or assets (corporate transaction may include, for example,
        any merger, financing, acquisition, divestiture or dissolution
        transaction or proceeding). We may transfer your personal information in
        the event of an insolvency, bankruptcy, or receivership. If another
        company acquires our company, business, or assets, that company will
        possess the personal information collected by us and will assume the
        rights and obligations regarding your personal information as described
        in this Statement.
      </p>

      <subhead className="sub-heading">PERSONAL INFORMATION SECURITY</subhead>

      <p>
        XCheck maintains appropriate and reasonable administrative, technical,
        and physical safeguards designed to help protect your personal
        information to prevent loss, misuse, and unauthorized access,
        disclosure, alteration, and destruction. However, no transmission over
        the internet is 100% secure. We encourage you to protect your usernames
        and passwords to help prevent anyone from accessing or abusing your
        Application Services account(s), including not using the same passwords
        for the Application Services that you use with other accounts. If you
        have any questions about the security of your personal information, you
        can contact us at info@xcheck.org
      </p>

      <subhead className="sub-heading">
        YOUR INFORMATION CHOICES AND CHANGES
      </subhead>

      <p>
        In addition to the choices outlined in other parts of this Statement,
        you also have control over the following:{" "}
      </p>
      <p>
        Access, Correction, and Deletion. If you wish to request access to or
        correction or deletion of personal information about you that we hold,
        you have some controls available to you from the “Account Settings”
        portion of the Application Services. You can also contact our Privacy
        Program at info@xcheck.org or our Data Protection Officer at
        info@xcheck.org However, to the extent permitted by applicable law, we
        reserve the right to charge a fee or decline requests that are
        unreasonable or excessive, where providing the information would be
        prohibited by law or could adversely affect the privacy or other rights
        of another person, or where we are unable to authenticate you as the
        person to whom the information relates.
      </p>
      <p>
        Email Communications. You may opt-out of receiving promotional or
        marketing emails from XCheck by following the instructions in those
        emails. If you opt-out, we may still send you non-promotional emails,
        such as emails about your XCheck projects or our ongoing business
        relationship.{" "}
      </p>
      <p>
        Application(s). You can stop all collection of information by our
        Application(s) by uninstalling the Application(s). You may use the
        standard uninstall processes as may be available as part of your mobile
        device or via the mobile application marketplace or network.
      </p>
      <p>
        Do Not Track chekals. Some web browsers may transmit chekals instructing
        websites and other online services not to track information. In our
        opinion, no standard governs what, if anything, websites should do if
        they receive these chekals. XCheck currently does not respond to such
        browser chekals or similar mechanisms instructing not to track
        information. If and when a standard is established, we may revise our
        position on responding to these chekals. Third parties may collect
        personal information about your online activities over time and across
        sites when you visit our Site or use the XCheck Service.{" "}
      </p>
      <p>
        Social Media Widgets. As noted above, our website may include social
        media features, such as the Facebook and LinkedIn button or interactive
        mini-programs that run on our website. These features may collect your
        IP Address, which page you are visiting on our Site and may set a cookie
        to enable the feature to function correctly. Social media features and
        widgets are either hosted by a third party or hosted directly on our
        website. Your interactions with these features are governed by the
        privacy statement or notice of the third party providing it.
      </p>

      <subhead className="sub-heading">12. Proprietary Rights Notice.</subhead>

      <p>
        If you are a California resident and the processing of personal
        information about you is subject to the California Consumer Privacy Act
        (“CCPA”), beginning January 2020, you have certain rights with respect
        to that information. In particular, you have a right to request that we
        provide you with the following information:
      </p>
      <p>
        1.The categories and specific pieces of personal information we have
        collected about you;
      </p>
      <p>
        2.The categories of sources from which we collect personal information;
      </p>
      <p>
        3.The purposes for collecting, using, or selling personal information;
      </p>
      <p>
        4.The categories of third parties with which we share personal
        information;
      </p>
      <p>
        5.For companies that disclose personal information for a business
        purpose, the categories of personal information disclosed about you for
        a business purpose; and
      </p>
      <p>
        6.For companies that “sell” personal information, the categories of
        personal information sold about you and the categories of third parties
        to which the personal information was sold, by category or categories of
        personal information for each third party to which the personal
        information was sold.
      </p>
      <p>
        To make such requests, contact us at compliance@XCheck.com or the other
        contact information set out below. Note that we have provided the
        information described in (2)-(5) in this privacy statement. Concerning
        (6) above, we do not “sell” personal information as defined by the CCPA.
      </p>
      <p>
        You also have a right to request that we delete personal information
        under certain circumstances, subject to several exceptions. Finally, you
        have a right not to be discriminated against for exercising rights set
        out in the CCPA.
      </p>

      <subhead className="sub-heading">EUROPEAN DATA PROTECTION RIGHTS</subhead>

      <p>
        If the processing of personal information about you is subject to
        European Union data protection law, you have certain rights with respect
        to that information:{" "}
      </p>
      <p>
        You can request access to, and rectification or erasure of, personal
        information;{" "}
      </p>
      <p>
        If any automated processing of personal data is done with your consent
        or under a contract with you, you have a right to transfer or receive a
        copy of the personal information in a usable and portable format;
      </p>
      <p>
        If the processing of personal data is based on your consent, you can
        withdraw consent at any time for future processing;
      </p>
      <p>
        You can to object to, or obtain a restriction of, the processing of
        personal information under certain circumstances; and
      </p>
      <p>
        For residents of France, you can send us specific instructions regarding
        the use of your data after your death.
      </p>
      <p>
        To make such requests or contact our Data Protection Officer (“DPO”) at
        info@xcheck.orgor at the contact information set out below. We process
        data as a processor on behalf of our Customers. If you are a customer of
        our Customer, you should put requests to them or contact them. You also
        have the right to lodge a complaint with a supervisory authority, but we
        encourage you to first contact us with any questions or concerns.
      </p>
      <p>
        We may rely on different lawful bases for collecting and processing
        personal information about you, for example, with your consent and/or as
        necessary to provide the products you use, operate our business, meet
        our contractual and legal obligations, protect the security of our
        systems and our customers, or fulfill other legitimate interests.
      </p>

      <subhead className="sub-heading">
        EU-U.S. PRIVACY SHIELD AND SWISS-U.S. PRIVACY SHIELD
      </subhead>

      <p>
        {" "}
        XCheck participates in and has certified its compliance with the EU-U.S.
        Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework for
        its collection, use, and retention of personal information transferred
        from the European Union and Switzerland to the United States. XCheck is
        committed to subjecting all personal data received from EU member
        countries, the United Kingdom, and Switzerland, respectively, to the
        Frameworks’ applicable principles (“Principles”). If there is any
        conflict between the terms in this Privacy Statement and the Privacy
        Shield Principles, the Principles shall govern. To learn more about the
        Privacy Shield Frameworks, or to check our status, visit the US
        Department of Commerce’s Privacy Shield website at
        https://www.privacyshield.gov/Program-Overview.
      </p>
      <p>
        XCheck is responsible for the processing of personal information it
        receives, under each Privacy Shield Framework, and subsequently
        transfers to a third party acting as an agent on its behalf. If
        third-party agents process personal information on our behalf in a
        manner inconsistent with the principles of either Privacy Shield
        Framework, we remain liable unless we prove we are not responsible for
        the event giving rise to the damage. XCheck complies with the Principles
        for all onward transfers of personal information from the EU, the United
        Kingdom, and Switzerland, including the onward transfer liability
        provisions.
      </p>
      <p>
        With respect to personal information received or transferred pursuant to
        each Privacy Shield Framework, XCheck is subject to the regulatory
        enforcement powers of the US Federal Trade Commission. In certain
        situations, XCheck may be required to disclose personal information in
        response to lawful requests by public authorities, including to meet
        national security or law enforcement requirements.
      </p>
      <p>
        In compliance with the Principles, XCheck commits to resolve any
        complaints about your privacy and our collection, use, or disclosure of
        your personal information. If you have any inquiries or complaints
        regarding this Statement or our compliance with Privacy Shield, you
        should first contact XCheck at the contact information set forth below.
      </p>
      <p>
        You may have the right to access or submit requests to review, correct,
        update, or delete your personal information. You can ask to review of
        correct personal information that we maintain about you by sending a
        written request to info@xcheck.org. We may limit or deny access to
        personal information where providing such access to unreasonably
        burdensome, expensive under the circumstances, or as otherwise permitted
        by the Privacy Shield Principles.
      </p>
      <p>
        Individuals who submit an inquiry or complaint to XCheck but do not
        receive an acknowledgment from us or think the complaint or concern has
        not been satisfactorily addressed, please contact our US-based third
        party dispute resolution provider (free of charge) a
        https://feedback-form.truste.com/watchdog/request. You must include the
        following information: the name of the company, the alleged privacy
        violation, your contact information, and whether you would like the
        particulars of your complaint shared with the company. Under certain
        conditions, more fully described here, you may be entitled to invoke
        binding arbitration when other dispute resolution procedures have been
        exhausted. In addition, the US Federal Trade Commission is the statutory
        body that has jurisdiction to hear any claims against XCheck regarding
        possible unfair or deceptive practices and violations of laws or
        regulations governing privacy.
      </p>

      <subhead className="sub-heading">LOCATION OF PERSONAL DATA</subhead>

      <p>
        {" "}
        The personal information we collect may be stored and processed in your
        country or region, or in any other country where we or our affiliates,
        subsidiaries, or service providers maintain facilities. Currently, we
        primarily use data centers in the United States. The storage location is
        chosen to operate efficiently and improve performance. We take steps
        designed to ensure that the personal information we collect under this
        Statement is processed according to the provisions of this Statement and
        applicable law wherever the data is located.
      </p>
      <p>
        <u>Location of Processing European Personal Data</u>. We transfer
        personal information from the European Economic Area and Switzerland to
        other countries, some of which have not been determined by the European
        Commission to have an adequate level of data protection. When we do so,
        we use a variety of legal mechanisms, including contracts, to help
        ensure your rights and protections. To learn more about the European
        Commission’s decisions on the adequacy of personal data protection,
        please visit
        https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en.{" "}
      </p>

      <subhead className="sub-heading">RETENTION OF PERSONAL DATA</subhead>
      <p>
        We retain personal information or personal data for as long as necessary
        to provide the products and fulfill the transactions you have requested,
        comply with our legal obligations, resolve disputes, enforce our
        agreements, and other legitimate and lawful business purposes. These
        needs can vary for different information types in the context of
        different products. Accordingly, our actual retention periods can vary
        significantly based on criteria such as user expectations or consent,
        the sensitivity of the information, the availability of automated
        controls that enable users to delete data, and our legal or contractual
        obligations.
      </p>
      <subhead className="sub-heading">CHANGES TO THIS STATEMENT</subhead>
      <p>
        XCheck may change this Statement from time to time to reflect changes in
        our offerings, how we collect, use, or disclose personal information, or
        applicable law. If we make any changes to this Statement, we will change
        the “Last Updated” date above. If we make any material changes, we will
        provide notice or obtain consent regarding such modifications as may be
        required by law.
      </p>
      <br />
      <br />
    </div>
  </React.Fragment>
);

const myStyles = {
  container: {
    marginTop: 100,
    color: "#000",
  },
  p: {
    fontSize: "1.2rem",
  },
};
export default Privacypolicy;
