import firebase from "firebase";

export default () => {
  if (firebase.apps.length === 0) {
    firebase.initializeApp({
      apiKey: "AIzaSyBVrf3B-Xsyrel46vss9tRUhmRGSz4dhQw",
      authDomain: "xcheck-a62a9.firebaseapp.com",
      databaseURL: "https://xcheck-a62a9.firebaseio.com",
      projectId: "xcheck-a62a9",
      storageBucket: "xcheck-a62a9.appspot.com",
      messagingSenderId: "497313539951",
      appId: "1:497313539951:web:ced3ab2e235f65d951aa9a",
      measurementId: "G-15P8JQ4SW3",
    });
  }
  const db = firebase.firestore();
  // const storage = firebase.storage();
  return db;
};
