import React from "react";

const Solutions = () => (
  <React.Fragment>
    <div className="industry-bg-gray">
      <div className="container g-py-100--md g-py-50--sm">
        <div className="g-mb-50">
          <img
            className="square-img"
            src="assets/img/title-square.svg"
            alt="svgicon"
          />
          <h2 className="h1 title-size title-color">XCheck helps News Publishers</h2>
        </div>
        <div
          className="masonry-grid row g-mb-40--lg"
          style={{ position: "relative", height: "1411.45px" }}
        >
          <div className="masonry-grid-sizer col-sm-1" />
          <div
            className="masonry-grid-item col-sm-6 col-lg-4 g-mb-30"
            style={{ position: "absolute", left: "66.579%", top: 0 }}
          >
            <article className="item-industry">
              <img
                className="img-fluid w-100 g-rounded-top-5"
                src="assets/img/6ic_soluciones_compliance.svg"
                alt=" Description"
              />
              <div className="g-pa-30 g-pa-10--md g-rounded-bottom-5 text-center">
                <h2 className="h5 mb-4"style={{ textDecorationLine: 'underline'}}>Protection</h2>
                <div className="img-text">
                  <div className="description"style={{ fontSize: "1.1em"}}>
                  News stay secure until consumed by readers on digital device. Ensuring protection of valuable news assets on internet - driving revenue &amp; brand visibility.  
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div
            className="masonry-grid-item col-sm-6 col-lg-4 g-mb-30"
            style={{ position: "absolute", left: "66.579%", top: 0 }}
          >
            <article className="item-industry">
              <img
                className="img-fluid w-100 g-rounded-top-5"
                src="assets/img/7ic_soluciones_contrato.svg"
                alt="svgicon"
              />
              <div className="g-pa-30 g-pa-10--md g-rounded-bottom-5 text-center">
                <h2 className="h5 mb-4"style={{ textDecorationLine: 'underline'}}>Trust</h2>
                <div className="img-text">
                  <div className="description"style={{ fontSize: "1.1em"}}>
                  XCheck badge separates news website that are doing reliable journalism from others. Badge empowers readers to further investigate origin and transparency of news
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div
            className="masonry-grid-item col-sm-6 col-lg-4 g-mb-30"
            style={{ position: "absolute", left: "66.579%", top: 0 }}
          >
            <article className="item-industry">
              <img
                className="img-fluid w-100 g-rounded-top-5"
                src="assets/img/10ic_soluciones_banca.svg"
                alt="Description"
              />
              <div className="g-pa-30 g-pa-10--md g-rounded-bottom-5 text-center">
                <h2 className="h5 mb-4"style={{ textDecorationLine: 'underline'}}>Insights</h2>
                <div className="img-text">
                  <div className="description" style={{ fontSize: "1.1em"}}>
                   Our monitoring engine keeps an eye on plagiarism, content manipulation and unauthorized distribution. We analyze and build evidence against the repeat offenders 
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div
          className="masonry-grid row"
          style={{ position: "relative", height: "1411.45px" }}
        >
          <div className="masonry-grid-sizer col-sm-1" />
        </div>
      </div>
    </div>

    <section className="color-blue g-pa-55 footer-press">
      <div className="d-md-flex justify-content-md-center text-center">
        <div className="align-self-md-center">
          <p
            className="lead g-font-weight-400 g-mr-20--md g-mb-15 g-mb-0--md"
            style={{ color: "#000000", textDecoration:"underline"}}
          >
            Protecting Internet Journalism
          </p>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default Solutions;
