/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Donations = () => (
  <React.Fragment>
    <main>
      <div className="background-grey">
        <section className="container g-pt-150">
          <div className="g-mb-50">
            <h2 className="h1 title-color">Donations</h2>
          </div>

          <section className="container g-pb-50 without-padding-about-us">
            <div className="row figures">
              <div className="col-lg-2 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="/placeholderimg.png"
                      alt="Image Description"
                    />
                  </figure>
                  <div className="g-bg-white  g-px-15--xl">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">Mahesh Babu</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="/placeholderimg.png"
                      alt="Image Description"
                    />
                  </figure>
                  <div className="g-bg-white  g-px-15--xl">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">Ronaldo</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="/placeholderimg.png"
                      alt="Image Description"
                    />
                  </figure>
                  <div className="g-bg-white  g-px-15--xl">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">Krishna Iyer</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="/placeholderimg.png"
                      alt="Image Description"
                    />
                  </figure>
                  <div className="g-bg-white  g-px-15--xl">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">Krishna Iyer</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="/placeholderimg.png"
                      alt="Image Description"
                    />
                  </figure>
                  <div className="g-bg-white  g-px-15--xl">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">Krishna Iyer</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6 g-mb-30">
                <div className="u-info-v1-2">
                  <figure className="u-block-hover">
                    <img
                      className="w-100"
                      src="/placeholderimg.png"
                      alt="Image Description"
                    />
                  </figure>
                  <div className="g-bg-white  g-px-15--xl">
                    <div className="g-mb-15">
                      <h4 className="h5 g-color-black g-mb-5">Krishna Iyer</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </main>
  </React.Fragment>
);

export default Donations;
