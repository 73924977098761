import React from "react";

const Government = () => (
  <React.Fragment>
    <div>
      <section className="container">
        <div className="hero-container pageBannerPadding">
          <div className="row">
            <div className="col-md-12">
              <div className="w-60 mx-auto">
                <h1 className="text-center newsText">
                Gain intelligence for informed decisions.
                </h1>
                <div className="summary mt-5">
                  <p
                    className="g-font-size-20 summary-font text-center lineHeight ">
                    XCheck works in collaboration with the public communication
                    departments of state or central government such as the
                    Directorate of Public Relations or the Department of
                    Information technology of the states, to identify
                    manipulation of government information and thus, curb
                    misinformation and fake news concerning important
                    administrative information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-industries g-pt-70 g-pb-50--lg">
        <div className="container">
          <div className="row d-flex align-items-lg-center flex-wrap">
            <div className="col-md-12 col-lg-6 g-mb-50--md g-pr-60--lg">
              <img
                className="img-fluid wd-100"
                src="../assets/img/compliance/1.jpg"
                alt=" Description"
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="text-mobile-finance g-ml-20 g-ml-0--sm">
                <div className="g-mb-20">
                  <div className="g-mb-40">
                    <h2 className="h1 title-color">Match speed of internet</h2>
                  </div>
                  <p className="g-font-size-16 summary-font">
                  Seprate wheat from chaff. Use Anemoi&trade; to identify and detect harmful rumors,
                   learn which issues to focus on, and shape public messaging.
                  </p>

                  <p className="g-font-size-16 summary-font">
                    Citizens do not engage in the cumbersome process of tracking
                    the usage and the reach of the published press releases,
                    notifications, policy information and scheme related
                    developments. This widens the communication between the
                    government, news media and the citizens. The communication
                    method of the government goes for a toss if public
                    engagement with accurate administrative information or
                    policies significant for the public cannot be assured.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-white-industries">
        <div className="container g-pt-100 g-pb-70">
          <div className="g-mb-50">
            <h2 className="h1 title-color text-center">How Anemoi&trade; works for government?</h2>
          </div>
          <div className="g-mt-minus-30 g-mt-0--md">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="item-check">
                  <div className="text-check">
                    <p className="g-font-size-16 text-epigraphy">
                      Anemoi&trade; allows government department to extract intelligence from 
                      Public News Register&trade;. The data gathering method of XCheck&trade; enables the
                      authorities to filter narrative that need
                      greater focus. They can also identify notorious actors in
                      the online publishing domain and take action against them.
                      Through such categorization, effective communication
                      strategies can be adopted to ensure that the right message
                      reaches the citizens.
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Public Engagement </p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Government departments can allow XCheck to be the first
                      step in the communication process and news media
                      organisations interested in publishing that particular
                      press release can then claim.
                    </p>
                  </div>
                </div>
                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Misinformation Tacking</p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      With its source-tracing method, XCheck allows the
                      government to digitally secure published information,
                      manage rights, regulate its usage, curb fake news and use
                      the analytics to adopt effective communication strategies.
                    </p>
                  </div>
                </div>

                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Fake News Monitoring</p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Protect the public communication from mala fide interests
                      and manipulations as altered versions are available in the
                      public register for auditing.
                    </p>
                  </div>
                </div>

                <div className="item-check">
                  <div className="img-check">
                    <img
                      className="mr-5"
                      src="../assets/img/web/ic_check.svg"
                      alt="svg_icon"
                    />
                  </div>
                  <div className="text-check">
                    <p className="g-font-size-20 text-title">Public Concerns</p>
                    <div className="hr"></div>
                    <p className="g-font-size-16 text-epigraphy">
                      Collate data points on news engagements, focus on subjects
                      that matter to the masses, shape better policies that are
                      driven by public concerns, and identify the best suitable
                      approach for campaign and that bring desired public
                      engagement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </React.Fragment>
);

export default Government;
