import React from "react";

const NewsAgency = () => (
  <React.Fragment>
    <div>
      <section className="container">
        <div className="hero-container pageBannerPadding">
          <div className="row">
            <div className="col-md-12">
              <div className="w-60 mx-auto">
                <h1 className="text-center newsText">
                  Protecting supply-chain integrity, access and verifiability
                  over internet
                </h1>
                <div className="summary mt-5">
                  <p
                    className="g-font-size-20
                summary-font text-center lineHeight
                "
                  >
                    Unlocking brand potential, revenue opportunities by
                    leveraging power of technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-industries g-pt-70 g-pb-50--lg">
        <div className="container">
          <div className="row d-flex align-items-lg-center flex-wrap">
            <div className="col-md-12 col-lg-6 g-mb-50--md g-pr-60--lg">
              <img
                className="img-fluid wd-100"
                src="../assets/img/agency.png"
                alt=" Description"
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="text-mobile-finance g-ml-20 g-ml-0--sm">
                <div className="g-mb-20">
                  <div className="g-mb-40">
                    <h2 className="h1 title-color">
                      News Agency Business Model
                    </h2>
                  </div>
                  <p className="g-font-size-16 summary-font">
                    Reuters, Bloomberg, AP, Press Trust of India and others
                    charge thousands of dollars for exclusive news pipeline.
                    Once publisher by news publishers on website, content is
                    plagiarized within minutes and distributed over internet by
                    unauthorized publishers hurting brand and revenues.
                    Unprotected supply chain has provided misinformation,
                    disinformation and fake news to flourish.
                  </p>
                  <p className="g-font-size-16 summary-font">
                    {" "}
                    XCheck helps news agency protect their supply chain, enhance
                    brand visibility and protect their valuable assets from
                    abuse over internet. Opening up doors for news syndication,
                    revenue and other revenue models.
                  </p>
                  <br />
                  <a
                    className="u-link-v5 g-cursor-pointer product-btn-green"
                    href="\xchecklabel"
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-industries  g-pb-50--lg">
        <div className="container">
          <div className="row d-flex align-items-lg-center flex-wrap">
            <div className="col-md-12 col-lg-6 g-mb-50--md g-pr-60--lg">
              <div className="g-mb-40">
                <h2 className="h1 title-color">Newsecure&trade; from XCheck</h2>
              </div>
              <p className="g-font-size-16 text-epigraphy">
                Newsecure&trade; solution from XCheck integrates seamless with
                existing infrastructure of news agency with minimum hassle.
                Allowing news agency to regulate rights, content distribution
                and protection over internet.
              </p>
              <p className="g-font-size-16 text-epigraphy">
                With click on button Newsecure&trade; allows subscribers users
                claim digital copy and distribute with confidence. Unsubscribers
                users pay and claim and copy providing blue ocean opportunities
                of revenue for News Agency.
              </p>
              <br />
              <a
                className="u-link-v5 g-cursor-pointer product-btn-green"
                href="\publishers"
              >
                Schedule Demo
              </a>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="text-mobile-finance g-ml-20 g-ml-0--sm">
                <div className="g-mb-20">
                  <img
                    className="img-fluid wd-100"
                    src="../assets/img/newsecure.png"
                    alt=" Description"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </React.Fragment>
);

export default NewsAgency;
